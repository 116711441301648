var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "badge align-middle align-text-top align-text-bottom clickable",
      class: [_vm.variantStyle, _vm.pill, _vm.readOnlyClass],
      on: _vm._d({}, [_vm.badgeClickEvent, _vm.badgeClick]),
    },
    [
      _vm.errorMessage
        ? _c("b-popover", {
            attrs: {
              target: "BADGE_ERROR_" + _vm.id,
              placement: "top",
              triggers: "hover",
              content: _vm.errorMessage,
            },
          })
        : _vm._e(),
      _vm.errorMessage
        ? _c("font-awesome-icon", {
            staticClass: "mr-1 error-align",
            staticStyle: { color: "red", "font-size": "1.125em" },
            attrs: {
              id: "BADGE_ERROR_" + _vm.id,
              icon: ["fas", "triangle-exclamation"],
            },
          })
        : _vm._e(),
      !_vm.edit
        ? _c(
            "div",
            { staticClass: "badge-text" },
            [
              _vm._v(_vm._s(_vm.text)),
              _vm.attribute !== null
                ? _c("span", { staticClass: "text-attr" }, [
                    _vm._v(_vm._s(_vm.attribute)),
                  ])
                : _vm._e(),
              _vm.icon !== null
                ? _c("font-awesome-icon", {
                    staticClass: "ml-1 text-icon",
                    attrs: { icon: ["far", _vm.icon] },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.edit
        ? _c("div", { staticClass: "autocomplete" }, [
            _c("span", { staticClass: "hidden-edit-val" }, [
              _vm._v(_vm._s(_vm.editVal)),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.editVal,
                  expression: "editVal",
                },
              ],
              ref: "edit",
              staticClass: "badge-edit",
              attrs: { list: "listvalues", autofocus: "true" },
              domProps: { value: _vm.editVal },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onEnter.apply(null, arguments)
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.editVal = $event.target.value
                  },
                  _vm.onInput,
                ],
                keydown: _vm.onKeydown,
                blur: _vm.onBlur,
              },
            }),
          ])
        : _vm._e(),
      !_vm.readOnly
        ? _c(
            "span",
            {
              staticClass: "clickable badge-close",
              on: _vm._d({}, [_vm.badgeRemoveEvent, _vm.badgeRemove]),
            },
            [
              _c("font-awesome-icon", {
                staticClass: "edit-icon-color",
                attrs: { icon: ["far", "xmark"] },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }