var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      ref: "badgegroup",
      staticClass: "badge-group",
      class: { "read-only": _vm.readOnly },
      attrs: { ghost: "sort-ghost", disabled: _vm.readOnly },
      on: { change: _vm.change },
      model: {
        value: _vm.list,
        callback: function ($$v) {
          _vm.list = $$v
        },
        expression: "list",
      },
    },
    [
      _vm._l(_vm.list, function (item, index) {
        return [_vm._t("default", null, { item: item, index: index })]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }